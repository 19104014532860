import NodeAPI from "@lib/api/nodeApi";
import { useEffect, useMemo } from "react";
import useSWR from "swr";
import API from "@lib/api/api";

import { useUserSession } from "./useUserSession";
import { useAtom } from "jotai";
import {
  dynamicBalanceAtom,
  fakeBalanceAtom,
  inPlayAtom,
  userBalanceTypeAtom,
} from "@store/wallet";
import { useHydrateAtoms } from "jotai/utils";

const defaultRate = {
  rate: 1,
  display: {
    isDefault: true,
    prepend: "$",
    append: null,
    icon: "https://cdn.rainbet.com/currencies%2Fusd.svg",
  },
};

const fetchCurrencies = () =>
  API.get("public/currencies").then((res) => res.data);

export const fetchWallet = ([, token], headers = {}) =>
  NodeAPI.get("v1/user/wallet", {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  }).then((res) => res.data);

export default function useWallet() {
  const { userData, data: session, hasToken, token } = useUserSession();

  const { data: walletBalanceList, mutate: balanceMutate } = useSWR(
    hasToken ? ["user/wallet", token] : null,
    fetchWallet,
    {
      revalidateOnFocus: true,
      revalidateOnMount: !session?.user?.wallet,
      keepPreviousData: true,
      fallbackData: session?.user?.wallet,
    }
  );

  const { data: currenciesData } = useSWR(
    "public/currencies",
    fetchCurrencies,
    {
      revalidateOnFocus: true,
      revalidateOnMount: !session?.user?.currencies,
      keepPreviousData: true,
      refreshInterval: 600000, // Refresh every 10 minutes
      fallbackData: session?.user?.currencies,
    }
  );

  useHydrateAtoms([[fakeBalanceAtom, walletBalanceList?.active?.primary]]);

  const [inPlay, setInPlay] = useAtom(inPlayAtom);
  const [dynamicBalance, setDynamicBalance] = useAtom(dynamicBalanceAtom);
  const [fakeBalance, setFakeBalance] = useAtom(fakeBalanceAtom);
  const [userBalanceType, setUserBalanceType] = useAtom(userBalanceTypeAtom);
  const setUserActiveBalance = setFakeBalance;
  const lockedBalance = dynamicBalance;

  const balanceFallback = useMemo(
    () => (userBalanceType ? userData?.balance_promo || 0 : userData?.balance),
    [userBalanceType, userData?.balance, userData?.balance_promo]
  );

  const userActiveBalance = useMemo(
    () =>
      dynamicBalance
        ? fakeBalance
        : userBalanceType
          ? walletBalanceList?.active?.promotional || 0
          : walletBalanceList?.active?.primary || balanceFallback,
    [
      balanceFallback,
      dynamicBalance,
      fakeBalance,
      userBalanceType,
      walletBalanceList?.active?.primary,
      walletBalanceList?.active?.promotional,
    ]
  );

  const activeRate = useMemo(
    () => currenciesData?.[walletBalanceList?.active?.currency] || defaultRate,
    [currenciesData, walletBalanceList?.active?.currency]
  );

  useEffect(() => {
    if (dynamicBalance) return;

    setFakeBalance(userActiveBalance);
  }, [dynamicBalance, setFakeBalance, userActiveBalance]);

  return {
    currencies: currenciesData,
    lockedBalance,
    balance: userActiveBalance,
    activeBalance: userActiveBalance,
    userActiveBalance,
    promoBalance: walletBalanceList?.active?.promotional || 0,
    setInPlay,
    inPlay,
    mutate: balanceMutate,
    balanceMutate: balanceMutate,
    userBalanceType,
    dynamicBalance,
    setDynamicBalance,
    setUserActiveBalance,
    setUserBalanceType,
    setLockBalance: setDynamicBalance,
    walletBalanceList,
    walletSetting:
      walletBalanceList?.active?.currency || userData?.currency?.code || "USD",
    activeRate,
    fakeBalance,
  };
}

import Image from "next/image";
import btc from "../assets/icons/crypto/btc.png";
import eth from "../assets/icons/crypto/eth.png";

import usd from "../assets/icons/fiat/usd.png";
import st from "../styles/items/ValueIcons.module.scss";
import Rain from "../assets/icons/crypto/Rain";
import ETH from "../assets/icons/crypto/ETH";
import LTC from "../assets/icons/crypto/LTC";
import BTC from "../assets/icons/crypto/BTC";
import USDT from "../assets/icons/crypto/USDT";

/**
 * Renders an icon based on the provided code.
 * @param {Object} props - The component props.
 * @param {string} props.code - The code for the icon.
 * @param {string} [props.size] - The size of the icon.
 * @param {string} [props.classItem] - The additional class for the icon.
 * @returns {JSX.Element|null} The rendered icon component.
 */

export default function ValueIcons(props) {
  const { code = "rain", size = false, classItem = "null" } = props;

  let icon = null;
  let classNameItem = `${st["value-icon"]}  ${size ? st[size] : null} ${
    st[classItem]
  }`;

  let iconCode = code.toLowerCase();
  switch (iconCode) {
    case "rain":
      icon = (
        <div className={classNameItem}>
          <Rain />
        </div>
      );
      break;
    case "eth":
      icon = (
        <div className={classNameItem}>
          <ETH />
        </div>
      );
      break;
    case "btc":
      icon = (
        <div className={classNameItem}>
          <BTC />
        </div>
      );
      break;
    case "ltc":
      icon = (
        <div className={classNameItem}>
          <LTC />
        </div>
      );
      break;
    case "usdt":
      icon = (
        <div className={classNameItem}>
          <USDT />
        </div>
      );
      break;
    case "brl":
      icon = (
        <div className={classNameItem}>
          <span className={st["text-icon"]}>R$</span>
        </div>
      );

      break;

    case "cad":
      icon = (
        <div className={classNameItem}>
          <span className={st["text-icon"]}>C$</span>
        </div>
      );

      break;
    default:
      icon = null;
      break;
  }

  return icon;
}

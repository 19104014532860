import Big from "big.js";

function validateNumber(value) {
  return value && !isNaN(Number(value)) ? value : 0;
  }

export const sBig = (amount) => {
  const validatedValue = validateNumber(amount);
  return Big(validatedValue);
}


import Big from "big.js";
import ValueIcons from "../../items/ValueIcons";
import { sBig } from "./numberHelpers";

const toFiat = (amount, rate) => {
  let balance = (rate * Number(amount)).toFixed(2);
  return balance;
};

const toCrypto = (value, rate) => {
  if (rate) {
    return (Number(value) / rate).toFixed(8);
  } else {
    return 0;
  }
};

// export const numFormatNoRound = (amount, decimal = 2) => {
//   const factor = Math.pow(10, decimal);
//   return (Math.floor(amount * factor) / factor).toFixed(decimal);
// };
// export const numFormat = (amount, decimal = 2) => {
//   return new Intl.NumberFormat("en-US", {
//     minimumFractionDigits: decimal,
//     maximumFractionDigits: decimal,
//   }).format(amount);
// };

function getClientLocale(defaultLocale = "en") {
  if (typeof window !== "undefined") {
    // Safe to use navigator because we're in the browser
    return navigator.language || defaultLocale;
  }
  return defaultLocale; // Fallback for SSR
}

/**
 * Formats the given amount based on the provided rate, decimal places, and formatting options.
 * OLD
 * @deprecated Use formatValueDisplay instead
 * @param {number} amount - The amount to be formatted.
 * @param {object} [rate={ rate: 1 }] - The rate object used for conversion.
 * @param {number} [decimal=3] - The number of decimal places to round the formatted amount.
 * @param {boolean} [raw=false] - Specifies whether to apply rates or use the raw amount.
 * @param {boolean} [symbol=false] - Specifies whether to include the currency symbol in the formatted amount.
 * @returns {string} The formatted amount.
 */
function formatValueAmount(
  amount = 0,
  rate = { rate: 1 },
  raw = false,
  symbol = false,
  locale = undefined
) {
  let balance = sBig(Number(amount));

  //Apply rates if not raw
  if (!raw) {
    balance = sBig(rate.rate).times(balance);
  }

  const userLocale = locale || getClientLocale(locale);

  let formatValue = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(balance.toFixed(2, 0));

  if (symbol) {
    formatValue = `${rate?.display?.prepend ?? ""}${formatValue} ${
      rate?.display?.append ?? ""
    }`;
  }

  // console.log("Format Value", formatValue);
  return formatValue;
}

/**
 * Formats the given amount based on the provided rate, decimal places, and formatting options.
 * NEW
 * @param {number} amount - The amount to be formatted.
 * @param {object} [options={}] - Formatting options.
 * @param {number} [options.rate.rate=1] - The rate used for conversion.
 * @param {boolean} [options.raw=false] - Specifies whether to apply rates or use the raw amount.
 * @param {boolean} [options.symbol=false] - Specifies whether to include the currency symbol in the formatted amount.
 * @param {string} [options.locale] - The locale used for formatting. If not provided, defaults to the client's locale.
 * @param {object} [options.round] - Specifies rounding options.
 * @param {number} [options.round.decimals=2] - Number of decimals to round to.
 * @param {string} [options.round.round.direction=false] - Direction to round ('up' or 'down').
 * @returns {string} The formatted amount.
 */
function formatValueDisplay(
  amount = 0,
  {
    reverse = false,
    rate = { rate: 1 },
    raw = false,
    decimals = 2,
    symbol = false,
    locale = undefined,
    round = { decimal: 2, direction: false },
  } = {}
) {
  let balance = sBig(amount);
  //Apply rates if not raw
  if (!raw) {
    if (reverse) balance = balance.lte(0) ? 0 : sBig(rate.rate).div(balance);
    else balance = sBig(rate.rate).times(balance);
  }

  // Apply rounding if specified
  if (round && round.direction) {
    if (round.direction === "up") {
      balance = balance.round(round.decimal, Big.roundUp);
    } else if (round.direction === "down") {
      balance = balance.round(round.decimal, Big.roundDown);
    } else {
      balance = balance.round(round.decimal, Big?.[round.direction]);
    }
  }

  const userLocale = locale || getClientLocale();
  let formatValue = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(balance.toFixed(decimals, 0));

  if (symbol) {
    formatValue = `${rate?.display?.prepend ?? ""}${formatValue} ${
      rate?.display?.append ?? ""
    }`;
  }

  return formatValue;
}

/**
 * Converts the given amount to the selected currency based on the provided rate and decimal places.
 *
 * @param {number} amount - The amount to be converted.
 * @param {object} rate - The rate object containing the conversion rate.
 * @param {number} rate.rate - The conversion rate.
 * @param {object} options - The options object.
 * @param {number} options.decimal - The number of decimal places to round the converted amount to. Default is 2.
 * @returns {number} The converted amount.
 */
function toCurrencySelected(amount, rate = { rate: 1 }, {} = {}) {
  let balance = sBig(rate.rate).times(sBig(amount));
  balance = Number(balance.toFixed(2, 0));

  return balance;
}

/**
 * Converts an amount using a rate.
 *
 * @param {number} amount - The amount to be converted.
 * @param {object} rate - The rate object containing the conversion rate.
 * @param {number} rate.rate - The conversion rate.
 * @returns {number} - The converted amount.
 */

function rateConvert(amount, rate = { rate: 1 }) {
  let newAmount = sBig(amount).times(sBig(rate.rate));
  return Number(newAmount.toFixed(2, 0));
}

function rateConvertRoundUp(amount, rate = { rate: 1 }, decimals = 2) {
  // Convert amount to Big and apply rate
  let newAmount = sBig(amount).times(sBig(rate.rate));

  // Convert to number and fix to the specified number of decimals
  return Number(newAmount.round(1, Big.roundUp).toFixed(decimals));
}

//depending on wallet setting, will change the output
const displayValue = (value, wallet, rates, crypto = false) => {
  let amount = value;
  let icon;

  if ("btc" in rates && wallet.activeFiat) {
    amount = toFiat(value, wallet, rates, crypto);
  }
  icon = <ValueIcons type={"crypto"} wallet={wallet} code={crypto} />;

  return { icon, amount };
};

//Converts different currencies to USD base amount
const fiatConvert = (amount, rate = { rate: 1 }) => {
  let newAmount = Number(amount) / Number(rate.rate);
  return Math.floor(newAmount * 10000) / 10000;
};

//Converts different currencies to USD base amount
const convertToBaseValue = (amount, rate = { rate: 1 }) => {
  let newAmount = Number(amount) / Number(rate.rate);
  return Math.floor(newAmount * 10000) / 10000;
};

export {
  toFiat,
  toCrypto,
  displayValue,
  formatValueAmount,
  fiatConvert,
  rateConvert,
  convertToBaseValue,
  toCurrencySelected,
  formatValueDisplay,
  rateConvertRoundUp,
};

import { atom } from "jotai";

// remember to use this if doing server side stuff
// import { useHydrateAtoms } from 'jotai/utils'

// do not use jotai for requests, use swr instead

export const inPlayAtom = atom(false);
export const dynamicBalanceAtom = atom(false);
export const fakeBalanceAtom = atom(0);
export const userBalanceTypeAtom = atom(false);

export default function ETH({width = 46, height="100%"}) {
    return(<svg width={width} height={height} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#EDF0F4"/>
    <path d="M22.9388 9.2002L22.7559 9.82169V27.8543L22.9388 28.0369L31.3092 23.0891L22.9388 9.2002Z" fill="#343434"/>
    <path d="M22.939 9.2002L14.5684 23.0891L22.939 28.0369V19.2843V9.2002Z" fill="#8C8C8C"/>
    <path d="M22.9391 29.6215L22.8359 29.7473V36.1708L22.9391 36.4718L31.3146 24.6763L22.9391 29.6215Z" fill="#3C3C3B"/>
    <path d="M22.939 36.4718V29.6215L14.5684 24.6763L22.939 36.4718Z" fill="#8C8C8C"/>
    <path d="M22.9395 28.0372L31.3098 23.0894L22.9395 19.2847V28.0372Z" fill="#141414"/>
    <path d="M14.5684 23.0894L22.939 28.0372V19.2847L14.5684 23.0894Z" fill="#393939"/>
    </svg>
    
    )
}